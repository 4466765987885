/* Container styling */
.collections-container {
    padding: 2rem;
    background-color: #000;
    color: #fff;
  }
  
  /* Title styling */
  .collections-title {
    font-family: 'Inter', sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    color: #00bfff;
    margin-bottom: 1rem;
  }
  
  /* Layout for collection cards */
  .collections-list {
    display: flex;
    gap: 1.5rem;
    overflow-x: auto;
    padding-bottom: 1rem;
  }
  
  /* Card styling */
  .collection-card {
    background-color: #111;
    border-radius: 8px;
    overflow: hidden;
    min-width: 220px;
    max-width: 240px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }
  
  /* Image styling */
  .collection-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  /* Details container */
  .collection-details {
    padding: 1rem;
  }
  
  /* Collection title styling */
  .collection-title {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  /* Progress bar container */
  .progress-bar {
    width: 100%;
    height: 8px;
    background-color: #333;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }
  
  /* Filled progress */
  .progress {
    height: 100%;
    background-color: #00bfff;
    border-radius: 4px 0 0 4px;
    transition: width 0.3s ease;
  }
  
  /* Sold info (percentage sold and item count) */
  .sold-info {
    display: flex;
    justify-content: space-between;
    font-size: 0.85rem;
    color: #aaa;
    margin-bottom: 0.5rem;
  }
  
  /* Description styling */
  .collection-description {
    font-family: 'Inter', sans-serif;
    font-size: 0.85rem;
    color: #ccc;
  }
  