/* Container styling */
.nft-checker-container {
    text-align: center;
    padding: 2rem;
    background-color: #f9f9f9; /* Soft light background */
    color: #333; /* Dark grey for text */
    border-radius: 12px;
    max-width: 100%;
    margin: 0 auto;
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  body{
    background-color: #f9f9f9; /* Soft light background */

  }
  
  /* Title styling */
  .nft-checker-title {
    font-family: 'Inter', sans-serif;
    font-size: 1.6rem;
    font-weight: 600;
    color: #222; /* Slightly darker grey */
    margin-bottom: 1.5rem;
  }
  
  /* Button styling */
  .check-button,
  .claim-button {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 2rem;
    margin: 0.5rem 0;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .check-button {
    background-color: #007aff; /* Apple-like blue */
    color: #fff;
  }
  
  .check-button:hover {
    background-color: #005bb5; /* Darker shade on hover */
    box-shadow: 0 4px 8px rgba(0, 90, 204, 0.2); /* Soft shadow on hover */
  }
  
  /* Disabled Claim Button */
  .claim-button {
    background-color: #e0e0e0; /* Light grey */
    color: #999; /* Dark grey text */
    cursor: not-allowed;
    border: none;
  }
  
  .claim-button:enabled {
    background-color: #007aff; /* Active state similar to check-button */
    color: #fff;
    cursor: pointer;
    border: none;
  }
  
  .claim-button:enabled:hover {
    background-color: #005bb5;
    box-shadow: 0 4px 8px rgba(0, 90, 204, 0.2);
  }
  
  /* NFT count display */
  .nft-count {
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    margin: 1rem 0;
    color: #555; /* Medium grey */
  }
  
  /* Responsive adjustments */
  @media (max-width: 500px) {
    .nft-checker-container {
      padding: 1.5rem;
    }
  
    .check-button,
    .claim-button {
      padding: 0.5rem 1.5rem;
      font-size: 0.9rem;
    }
  }
