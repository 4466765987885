/* LandingPage.css */

.landing-page {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  .landing-page h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .landing-page p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #666;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
  }
  
  .input-group .icon {
    font-size: 1.2rem;
    margin-right: 0.5rem;
    color: #888;
  }
  
  .input-group input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0.5rem;
  }
  
  button {
    padding: 0.75rem;
    font-size: 1rem;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  button:hover {
    background: #0056b3;
  }
  