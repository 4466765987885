/* General container styling */
.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #000;
  color: #fff;
}

/* Logo styling */
.logo-icon {
  height: 30px;
}

/* Navigation links styling */
.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.nav-links a:hover {
  color: #00bfff;
}

/* Icon styling */
.menu-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon {
  font-size: 1.25rem;
  cursor: pointer;
}

.connect-wallet {
  background: linear-gradient(to right, #007aff, #00bfff);
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

/* Hamburger menu styling for mobile */
.hamburger {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Responsive design */
@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #000;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }

  .nav-links.open {
    display: flex;
  }

  .hamburger {
    display: block;
  }

  .menu-icons .connect-wallet {
    display: none;
  }
}
