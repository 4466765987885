/* UsersCountListener.css */

/* Use a clean, modern sans-serif font */
.users-count-container {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    margin: 2rem auto;
    padding: 1.5rem;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 450px;
  }
  
  .users-count-title {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .users-count-number {
    font-size: 2.5rem;
    font-weight: 700;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .slider-container {
    width: 100%;
    height: 20px;
    background: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }
  
  .slider-fill {
    height: 100%;
    transition: width 0.5s ease, background 0.5s ease;
    border-radius: 10px;
  }
  
  .users-count-extra {
    font-size: 1rem;
    color: #28a745;
    margin-top: 0.5rem;
  }
  